import { useEffect, useState } from "react";

import { queryClient } from "services";

import {
  logUserAssignedToVariant,
  useFeatureFlag
} from "features/feature-flags";
import { FlattenedFlags } from "features/feature-flags/types";

export const REWARD_BIASES_VARIANT_TEST_ID = "reward-biases";

export enum RewardBias {
  BALANCED = "balanced",
  AVATAR_ONLY = "avatarOnly"
}

const Biases = Object.values(RewardBias);

export function useRewardBiasVariant(): RewardBias {
  const flag = useFeatureFlag(REWARD_BIASES_VARIANT_TEST_ID);
  const variant =
    Biases.find(variant => variant === flag) ?? RewardBias.BALANCED;

  const [loggedAnalytics, setLoggedAnalytics] = useState(false);
  useEffect(() => {
    if (loggedAnalytics) return;
    if (!flag) return;

    logUserAssignedToVariant({
      testId: REWARD_BIASES_VARIANT_TEST_ID,
      variant: variant
    });

    setLoggedAnalytics(true);
  }, [flag, loggedAnalytics, variant]);

  return variant;
}

const REWARD_BIASES_VARIANT_WITH_OVERRIDES_KEY = `${REWARD_BIASES_VARIANT_TEST_ID}withOverrides`;

queryClient.setQueryDefaults([REWARD_BIASES_VARIANT_WITH_OVERRIDES_KEY], {
  staleTime: Infinity,
  gcTime: Infinity
});

export function setRewardBiasesFeatureFlagValue(flags: FlattenedFlags) {
  const value = flags[REWARD_BIASES_VARIANT_TEST_ID];
  queryClient.setQueryData([REWARD_BIASES_VARIANT_WITH_OVERRIDES_KEY], value);
}

export function getRewardBiasesFeatureFlagValue() {
  return queryClient.getQueryData<RewardBias>([
    REWARD_BIASES_VARIANT_WITH_OVERRIDES_KEY
  ]);
}
