import User from "../models/User";
import UserStateSlice from "../models/UserStateSlice";

export function getUserSlice(state: any): UserStateSlice {
  return state.get("user");
}

export function getUserDetails(state: any): User {
  return getUserSlice(state).user;
}

export function getUserId(state: any) {
  return getUserDetails(state).userId;
}

export function getUserDisplayName(state: any) {
  return getUserDetails(state).displayName;
}

export function getUserEmail(state: any) {
  return getUserDetails(state).email;
}

export function getUserGivenName(state: any) {
  return getUserDetails(state).givenName;
}

export function getUserFamilyName(state: any) {
  return getUserDetails(state).familyName;
}

export function getUserYearGroup(state: any) {
  return getUserDetails(state).yearGroup;
}

export function getUserPhoneNumber(state: any) {
  return getUserDetails(state).phoneNumber;
}

export function getUserAccountType(state: any) {
  return getUserDetails(state).type;
}

export function getTeacherRole(state: any) {
  return getUserDetails(state).teacherRole;
}

export function getUserNickName(state: any) {
  return getUserDetails(state).nickname;
}

export function getUserManagedBy(state: any) {
  return getUserDetails(state).managedBy;
}

export function isUserManaged(state: any): boolean {
  return !!getUserManagedBy(state);
}

export function getUserChosenSchool(state: any) {
  return getUserDetails(state).school;
}

export function getUserRecoveryEmail(state: any) {
  return getUserDetails(state).recoveryEmail;
}

export function getUserRecoveryEmailConfirmed(state: any) {
  return getUserDetails(state).recoveryEmailConfirmed;
}

export function getUserMetaSlice(state: any): any {
  return getUserSlice(state).meta;
}

export function getUserLastVisitTime(state: any): any {
  return getUserDetails(state).lastVisitTime;
}

export function getTeacherSubjects(state: any) {
  return getUserDetails(state).teacherSubjects;
}

export function getStudentSubjects(state: any) {
  return getUserDetails(state).studentSubjects;
}
