import { waitForStore } from "store";

import logError from "seneca-common/utils/sentry/logError";

import { newAppVersionIsInstalling } from "features/app/state";
import { serviceWorkerActive } from "features/app/state/actions/pwa";

import pollForNewServiceWorkerOnTheServer from "./pollForNewServiceWorkerOnTheServer";
import { Config } from "./types";
import { debugLog, debugLogString } from "./utils";

export default function registerServiceWorker(swUrl: string, config: Config) {
  debugLogString(`Registering ${swUrl}...`);

  navigator.serviceWorker &&
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        debugLogString("Registration complete: ");
        debugLog(registration);

        pollForNewServiceWorkerOnTheServer(registration, config);

        listenForWaitingServiceWorker(registration, config);
      })
      .catch(error => {
        console.error("Error during service worker registration:", error);
        logError(error, {
          message: "Error during service worker registration:",
          fingerprint: ["service-worker", "registration"]
        });
      });
}

function listenForWaitingServiceWorker(
  reg: ServiceWorkerRegistration,
  config: Config
) {
  debugLogServiceWorkerStateChanges(reg, "waiting");
  debugLogServiceWorkerStateChanges(reg, "installing");

  if (reg.active) {
    dispatchServiceWorkerActive();
  }

  if (reg.waiting) {
    onUpdateReady(reg, config);
  }

  if (reg.installing) {
    waitForWorkerToInstall(reg, config);
  }

  reg.addEventListener("updatefound", () => {
    debugLogString("updatefound event fired");
    waitForWorkerToInstall(reg, config);

    // Installing worker was not present when this was called at the top of this function
    debugLogServiceWorkerStateChanges(reg, "installing");
  });
}

function waitForWorkerToInstall(
  reg: ServiceWorkerRegistration,
  config: Config
) {
  const installingWorker = reg.installing;

  if (!installingWorker) {
    debugLogString("no installing worker found, can't really do much!");
    return;
  }

  debugLogString("waiting for service worker to finish installing...");

  notifyUpdateIfReady();
  installingWorker.addEventListener("statechange", notifyUpdateIfReady);

  dispatchNewAppVersionInstalling();

  function notifyUpdateIfReady() {
    if (installingWorker?.state === "installed") {
      const oldServiceWorkerThatHasControl = navigator.serviceWorker.controller;

      if (oldServiceWorkerThatHasControl) {
        // New worker has been installed but there is currently another service worker in charge (as this point we show the 'update available' message to the user)
        console.log(
          "New content is available and will be used when all tabs for this page are closed. See https://bit.ly/CRA-PWA."
        );
        onUpdateReady(reg, config);
      } else {
        // There was no existing service worker so the newly installed one will take charge without needing to reload
        console.log("Content is cached for offline use.");
      }
    }
  }
}

function onUpdateReady(reg: ServiceWorkerRegistration, config: Config) {
  // This function is used to tell the new service worker to take control i.e. skip waiting and become active
  const activateNewServiceWorker = () => {
    debugLogString("Activating new service worker");

    (reg.installing || reg.waiting)!.postMessage({
      type: "SKIP_WAITING"
    });
  };

  config.onNewServiceWorkerReadyButWaiting({
    activateNewServiceWorker
  });
}

function debugLogServiceWorkerStateChanges(
  registration: ServiceWorkerRegistration,
  workerName: "waiting" | "installing"
) {
  const worker = registration[workerName];

  if (worker) {
    debugLogString(
      `"${workerName}" service worker found in ${worker.state} state`
    );
    debugLog(worker);
    worker.addEventListener("statechange", () => {
      debugLogString(`"${workerName}" worker changed state to ${worker.state}`);
    });
  }
}

async function dispatchServiceWorkerActive() {
  const store = await waitForStore();
  store.dispatch(serviceWorkerActive());
}

async function dispatchNewAppVersionInstalling() {
  const store = await waitForStore();
  store.dispatch(newAppVersionIsInstalling());
}
