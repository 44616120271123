import useAppVariant from "features/common/hooks/useAppVariant";
import { isAndroid, isIOS } from "features/common/utils/functions/mobile";

import { useGetIsAndroidTWA } from "../../android/hooks/useAndroidTWA";
import { isNativeAppiOS } from "../../ios/utils/isNativeAppiOS";

const showPromptOnMobileByVariant = {
  seneca: true,
  plurall: false,
  goStudentLearning: false
};

export function useCanShowAppPrompt() {
  const isMobileDevice = isIOS() || isAndroid();
  const isAndroidTWA = useGetIsAndroidTWA();
  const isUsingApp = isNativeAppiOS() || isAndroidTWA;
  const appVariant = useAppVariant();

  return (
    isMobileDevice && !isUsingApp && showPromptOnMobileByVariant[appVariant]
  );
}
