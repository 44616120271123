import {
  areSoundEffectsEnabled as areSoundEffectsEnabledState,
  getMapGradient as getMapGradientState,
  getModuleToolbarOpenByDefault as getModuleToolbarOpenByDefaultState,
  getPreferredThemeMode as getPreferredThemeModeState,
  getPreferredTheme as getPreferredThemeState,
  getTypingPreference as getTypingPreferenceState,
  getUserAccountType,
  getUserEmail,
  getUserFamilyName,
  getUserGivenName,
  getUserNickName
} from "seneca-common/features/user/state";

import { ThemeModePreference } from "features/dark-mode/consts";
import { getThemeModePreferenceFromLocalStorage } from "features/dark-mode/utils";

import { userAccountType } from "../consts";
import { MapGradientOptions, TypingOptions } from "../consts/preferences";
import {
  validateAndFormatMapGradient,
  validateAndFormatTypingOption
} from "../utils/preferences";

export { getUserAccountType };

export function isSchoolStudent(state: any): boolean {
  return getUserAccountType(state) === userAccountType.schoolstudent;
}

export function isSchoolTeacher(state: any): boolean {
  return getUserAccountType(state) === userAccountType.schoolteacher;
}

export function isSchoolParent(state: any): boolean {
  return getUserAccountType(state) === userAccountType.schoolparent;
}

export function isSchoolTeacherOrParent(state: any): boolean {
  return isSchoolTeacher(state) || isSchoolParent(state);
}

export function getNicknameOrName(state: any): string {
  return (
    getUserNickName(state) ||
    `${getUserGivenName(state)} ${getUserFamilyName(state)}`
  );
}

export function getNavbarName(state: any): string {
  const name = getUserGivenName(state);
  if (name) return name;
  const email = getUserEmail(state);
  return email ? email.split("@")[0] : "";
}

export function getUserTypeHasTopbar(state: any): boolean {
  return isSchoolParent(state) || isSchoolTeacher(state);
}

export const getTypingPreference = (state: any): TypingOptions =>
  validateAndFormatTypingOption(getTypingPreferenceState(state)!);

export const getModuleToolbarOpenByDefault = (state: any): boolean =>
  !!getModuleToolbarOpenByDefaultState(state);

export function getMapGradient(state: any): MapGradientOptions {
  return validateAndFormatMapGradient(getMapGradientState(state)!);
}

export function areSoundEffectsEnabled(state: any): boolean {
  const enabledInPreferences = areSoundEffectsEnabledState(state);

  if (enabledInPreferences === undefined || enabledInPreferences === null) {
    return true;
  }

  return enabledInPreferences;
}

export function getPreferredTheme(state: any) {
  return getPreferredThemeState(state);
}

export function getPreferredThemeMode(state: any): ThemeModePreference {
  return (
    getPreferredThemeModeState(state) ??
    getThemeModePreferenceFromLocalStorage()
  );
}
