export const preferencesActionPath: string = "seneca/user/preferences/";

export const preferenceUpdateOperations = {
  HAS_PICKED_DISPLAY_NAME: "HAS_PICKED_DISPLAY_NAME",
  MAP_GRADIENT: "MAP_GRADIENT",
  TYPING_PREFERENCE: "TYPING_PREFERENCE",
  MODULE_TOOLBAR_OPEN_BY_DEFAULT: "MODULE_TOOLBAR_OPEN_BY_DEFAULT",
  AGE_GROUP: "AGE_GROUP",
  REGION: "REGION",
  NOTIFY_WHEN_DATA_REQUEST_READY: "NOTIFY_WHEN_DATA_REQUEST_READY",
  SOUND_EFFECTS_ENABLED: "SOUND_EFFECTS_ENABLED",
  PREFERRED_THEME: "PREFERRED_THEME",
  PREFERRED_THEME_MODE: "PREFERRED_THEME_MODE"
};

export type PreferenceUpdateOperations =
  keyof typeof preferenceUpdateOperations;

export const MAP_GRADIENT_OPTIONS = Object.freeze({
  PERLOROUS: "PERLOROUS",
  SANGUINE: "SANGUINE",
  DEFAULT: "DEFAULT"
});

export type MapGradientOptions = keyof typeof MAP_GRADIENT_OPTIONS;

// Object needs to be frozen for $Values flow type to work
export const typingOptions = Object.freeze({
  OFF: "OFF",
  HALF: "0.5",
  ONE: "1.0",
  ONE_POINT_FIVE: "1.5",
  TWO: "2.0",
  TWO_AND_A_HALF: "2.5"
});

export type TypingOptions = (typeof typingOptions)[keyof typeof typingOptions];

export const ageGroupOptions = Object.freeze({
  A_LEVEL: "A Level",
  GCSE: "GCSE",
  KS3: "KS3",
  KS2: "KS2"
});

export type AgeGroupOptions =
  (typeof ageGroupOptions)[keyof typeof ageGroupOptions];

export function isValidTypingOption(input: string): input is TypingOptions {
  return !!Object.values(typingOptions).filter(pref => pref === input)[0];
}
