import { Action, Reducer } from "common/types/redux";

import {
  SubscriptionProduct,
  SubscriptionProductsStateSlice
} from "seneca-common/features/subscriptions/features/subscription-products/state/index";
import actionTypes from "seneca-common/features/subscriptions/state/action-types";
import {
  ReceivedSubscriptionProductType,
  ReceivedSubscriptionType
} from "seneca-common/features/subscriptions/state/types";

import {
  getIdFromReceivedSubscriptionProduct,
  transformReceivedSubscriptionProduct
} from "../reduxBundle";

export default function subscriptionProductsReducerWrapper(
  subscriptionProductsReducer: Reducer<SubscriptionProductsStateSlice>
) {
  return (
    state: SubscriptionProductsStateSlice,
    action: Action<ReceivedSubscriptionType[]>
  ) => {
    switch (action.type) {
      case actionTypes.RECEIVE_SUBSCRIPTIONS: {
        const receivedSubscriptions = action.payload;

        const receivedProducts =
          receivedSubscriptions?.reduce(
            (
              acc: ReceivedSubscriptionProductType[],
              sub: ReceivedSubscriptionType
            ) => {
              const upgradeProducts =
                sub.updateOnPaymentSuccess?.subscriptionProducts;

              if (upgradeProducts) {
                acc.push(...upgradeProducts);
              }

              return [...acc, ...sub.subscriptionProducts];
            },
            []
          ) ?? [];

        return (state ?? new SubscriptionProductsStateSlice()).withMutations(
          mutableState =>
            mutableState.update("subscriptionProducts", subscriptionProducts =>
              subscriptionProducts.withMutations(mutableProducts =>
                receivedProducts.reduce((acc, item) => {
                  const id = getIdFromReceivedSubscriptionProduct(item);
                  const existingItem = acc.get(id) ?? new SubscriptionProduct();

                  return acc.set(
                    id,
                    existingItem.mergeDeepWith(
                      (prevValue, newValue) => newValue ?? prevValue,
                      transformReceivedSubscriptionProduct(item)
                    )
                  );
                }, mutableProducts)
              )
            )
        );
      }

      default:
        return subscriptionProductsReducer(state, action);
    }
  };
}
