import { useSelector } from "react-redux";
import { appVersionUpdate } from "service-worker-registration";

import {
  getAppVersionIsUpdating,
  getNewAppVersionAvailable,
  getNewAppVersionInstalling,
  getServiceWorkerActive
} from "../state";

/**
 * Hook that provides the state of the app's service worker lifecycle and a function to trigger an app update when ready.
 *
 * @returns {function} update - A function to trigger a service worker update when in the waiting state.
 * @returns {boolean} active - Indicates whether the service worker is currently active.
 * @returns {boolean} installing - Indicates whether a new service worker is currently being installed.
 * @returns {boolean} ready - Indicates whether a new service worker is waiting and ready to be activated.
 * @returns {boolean} updating - Indicates whether a new service worker is currently in the process of activating.
 */
export default function useUpdateApp() {
  const active: boolean = useSelector(getServiceWorkerActive);
  const installing: boolean = useSelector(getNewAppVersionInstalling);
  const ready: boolean = useSelector(getNewAppVersionAvailable);
  const updating: boolean = useSelector(getAppVersionIsUpdating);

  return {
    update: appVersionUpdate.trigger,
    active,
    installing,
    ready,
    updating
  };
}
