import { useEffect, useState } from "react";

import { queryClient } from "services";

import {
  logUserAssignedToVariant,
  useFeatureFlag
} from "features/feature-flags";
import { FlattenedFlags } from "features/feature-flags/types";

export const USER_LEVEL_VARIANT_TEST_ID = "userLevel";
export const LEVELS_V1_AB_FLAG = "levels_v1";
const Variants = ["control", LEVELS_V1_AB_FLAG] as const;

export type UserLevelVariant = (typeof Variants)[number];

export function useUserLevelVariant(): UserLevelVariant {
  const flag = useFeatureFlag(USER_LEVEL_VARIANT_TEST_ID);

  const variant = !Variants.some(variant => variant === flag)
    ? "control"
    : (flag as UserLevelVariant);

  const [loggedAnalytics, setLoggedAnalytics] = useState(false);
  useEffect(() => {
    if (loggedAnalytics) return;
    if (!flag) return;

    logUserAssignedToVariant({
      testId: USER_LEVEL_VARIANT_TEST_ID,
      variant: variant
    });

    setLoggedAnalytics(true);
  }, [flag, loggedAnalytics, variant]);

  return variant;
}

const USER_LEVEL_VARIANT_WITH_OVERRIDES_KEY = `${USER_LEVEL_VARIANT_TEST_ID}withOverrides`;

queryClient.setQueryDefaults([USER_LEVEL_VARIANT_WITH_OVERRIDES_KEY], {
  staleTime: Infinity,
  gcTime: Infinity
});

export function setUserLevelFeatureFlagValue(flags: FlattenedFlags) {
  const value = flags[USER_LEVEL_VARIANT_TEST_ID];
  queryClient.setQueryData([USER_LEVEL_VARIANT_WITH_OVERRIDES_KEY], value);
}

export function getUserLevelFeatureFlagValue() {
  return queryClient.getQueryData<UserLevelVariant>([
    USER_LEVEL_VARIANT_WITH_OVERRIDES_KEY
  ]);
}
