import actionTypes from "../action-types/pwa";

export function serviceWorkerActive() {
  return {
    type: actionTypes.SERVICE_WORKER_ACTIVE
  };
}

export function newAppVersionIsInstalling() {
  return {
    type: actionTypes.NEW_VERSION_IS_INSTALLING
  };
}

export function newAppVersionIsAvailable() {
  return {
    type: actionTypes.NEW_VERSION_IS_AVAILABLE
  };
}

export function appVersionIsUpdating(time: number) {
  return {
    type: actionTypes.APP_VERSION_IS_UPDATING,
    payload: {
      time
    }
  };
}

export function dismissPrompts(time: number) {
  return {
    type: actionTypes.DISMISS_PROMPTS,
    payload: {
      time
    }
  };
}

export function showWarning() {
  return {
    type: actionTypes.SHOW_WARNING
  };
}

export function setLastSeenPrompt(time: number | null) {
  return {
    type: actionTypes.SET_LAST_SEEN_PROMPT,
    payload: time
  };
}

type NewPWAConfigReceivedArgs = {
  showWarning: boolean;
  forceUpdate: boolean;
  toastVisibilityTimeoutMS?: number;
};

export function newPWAConfigReceived({
  showWarning,
  forceUpdate,
  toastVisibilityTimeoutMS
}: NewPWAConfigReceivedArgs) {
  return {
    type: actionTypes.NEW_PWA_CONFIG_RECEIVED,
    payload: {
      showWarning,
      forceUpdate,
      toastVisibilityTimeoutMS
    }
  };
}

export function setUpdateToastHidden(promptHidden: boolean) {
  return {
    type: actionTypes.SET_UPDATE_PROMPT_VISIBILITY,
    payload: promptHidden
  };
}
