export const CRAM_MODE_GATING_TEST_ID = "cramModeGatingTest";

export enum CramModeGatingVariant {
  start = "multi-section-start",
  modules10 = "multi-section-10-modules",
  modules20 = "multi-section-20-modules"
}

export const CramModeGatingStartMessage = `Get Seneca Exam Boost to access cram mode & speed test your knowledge across multiple topics.`;
export const CramModeGatingSessionMessage = `Get Seneca Exam Boost to access unlimited cram mode & speed test your knowledge across multiple topics.`;

export const CramModeGatingMessage = {
  [CramModeGatingVariant.start]: CramModeGatingStartMessage,
  [CramModeGatingVariant.modules10]: CramModeGatingSessionMessage,
  [CramModeGatingVariant.modules20]: CramModeGatingSessionMessage
};
