import {
  SchoolInfoBySchoolIdDoesNotExistError,
  UserSchoolInfoDoesNotExistError
} from "@seneca/schools-service-client";
import { SchoolGroupI } from "@seneca/schools-service-types";

import { getClient } from "services";

const schoolsService = () => getClient("schoolsService");

export async function searchSchools(searchTerm: string) {
  return await schoolsService().searchSchoolsList(searchTerm);
}

export async function fetchMyTeacherSchools() {
  return await schoolsService().fetchMyTeacherSchools({
    excludeParentInfo: true
  });
}

export async function fetchMyStudentSchools() {
  return schoolsService().fetchMyStudentSchools();
}

export async function fetchTeacherSchoolsWithParentInfo() {
  return await schoolsService().fetchMyTeacherSchools();
}

export async function fetchTeacherClasses(schoolId: string) {
  return await schoolsService().getSchoolClassesTeacher(schoolId);
}

export async function joinSchoolClass(schoolId: string, classId: string) {
  return await schoolsService().joinSchoolClass({
    schoolId,
    classId
  });
}

export async function joinSchoolClasses(schoolId: string, classIds: string[]) {
  return await schoolsService().joinSchoolClasses({
    schoolId,
    classIds
  });
}

export async function inviteSchoolParents(
  schoolId: string,
  yearGroups?: number[]
) {
  return await schoolsService().inviteSchoolParents(schoolId, yearGroups);
}

export async function inviteSchoolClassesParents(
  schoolId: string,
  classIds: string[]
) {
  return await schoolsService().inviteSchoolParentsForClasses(
    schoolId,
    classIds
  );
}

export async function putMySchoolInfo(schoolId: string | undefined) {
  return await schoolsService().putMySchoolInfo({ schoolId });
}

export async function fetchMySchoolInfo() {
  try {
    return await schoolsService().fetchMySchoolInfo();
  } catch (e) {
    if (e instanceof UserSchoolInfoDoesNotExistError) {
      return null;
    }

    throw e;
  }
}

export async function fetchSchoolInfo(schoolId: string) {
  try {
    return await schoolsService().fetchSchoolInfo(schoolId);
  } catch (e) {
    if (e instanceof SchoolInfoBySchoolIdDoesNotExistError) {
      return undefined;
    }

    throw e;
  }
}

export async function fetchGroupForSchool(
  schoolId: string
): Promise<SchoolGroupI | undefined> {
  try {
    const response = await schoolsService().fetchGroupForSchool(schoolId);
    return response.group;
  } catch (e) {
    return undefined;
  }
}
