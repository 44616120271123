import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom-v5-compat";

import { setUserLevelFeatureFlagValue } from "seneca-common/features/levels/featureFlag";
import { setRewardBiasesFeatureFlagValue } from "seneca-common/features/rewards/featureFlag";
import { isSignedIn } from "seneca-common/features/user/state";

import { logABTestVariantsFallback } from "./ab-tests/analytics";
import getAllFeatureFlags from "./getAllFeatureFlags";
import { useFetchFeatureFlags } from "./service";
import { FlattenedFlags } from "./types";
import { setDevOverridesFromSearchParams } from "./utils";

type FlagContextT = {
  flags: FlattenedFlags;
  recalculateFlags: () => void;
};
const FlagContext = createContext<FlagContextT>({} as any);

export function FlagsProvider({ children }: any) {
  const [flags, setFlags] = useState<FlattenedFlags>({});

  const signedIn = useSelector(isSignedIn);
  const { search } = useLocation();
  const { data } = useFetchFeatureFlags({ enabled: signedIn });

  useEffect(() => {
    const { abTest, static: staticFlags, release } = getAllFeatureFlags(data);
    const flags = { ...abTest, ...staticFlags, ...release };
    const flagsWithOverrides = setDevOverridesFromSearchParams(flags, search);

    setFlags(flagsWithOverrides);
    setUserLevelFeatureFlagValue(flagsWithOverrides);
    setRewardBiasesFeatureFlagValue(flagsWithOverrides);
  }, [search, data]);

  useEffect(() => {
    const { abTest } = getAllFeatureFlags(data);
    logABTestVariantsFallback(abTest);
  }, [data]);

  const recalculateFlags = useCallback(() => {
    const {
      abTest: abTests,
      static: staticFlags,
      release: releaseFlags
    } = getAllFeatureFlags(data);
    const flags = { ...abTests, ...staticFlags, ...releaseFlags };

    setFlags(flags);
    setUserLevelFeatureFlagValue(flags);
  }, [data]);

  return (
    <FlagContext.Provider
      value={{
        flags,
        recalculateFlags
      }}
    >
      {children}
    </FlagContext.Provider>
  );
}

export const useFlagsContext = () => useContext(FlagContext);

export function useFeatureFlag<T extends string | boolean>(key: string) {
  const { flags } = useFlagsContext();

  return flags?.[key] as T;
}

export function withFeatureFlags<T>(Component: any) {
  return function WithFeatureFlags(props: T) {
    const { flags } = useFlagsContext();

    return <Component {...props} flags={flags} />;
  };
}

export function withFeatureFlag<T>(Component: any, key: string) {
  return function WithFeatureFlags(props: T) {
    const flag = useFeatureFlag(key);

    const newProps = { ...props, [key]: flag };

    return <Component {...newProps} />;
  };
}
