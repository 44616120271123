import PWAStateSlice from "../../../models/state/pwa/PWAStateSlice";

export function serviceWorkerActive(state: PWAStateSlice) {
  return state.serviceWorkerActive;
}

export function newAppVersionInstalling(state: PWAStateSlice) {
  return state.newVersionInstalling;
}

export function newAppVersionAvailable(state: PWAStateSlice) {
  return state.newVersionAvailable;
}

export function appVersionIsUpdating(state: PWAStateSlice) {
  return state.updating;
}

export function getLastSeenPrompt(state: PWAStateSlice) {
  return state.lastSeenPrompt;
}

export function promptNotSeenRecently(state: PWAStateSlice, now: number) {
  const { lastSeenPrompt, toastVisibilityTimeoutMS } = state;
  return (
    lastSeenPrompt === null || now - lastSeenPrompt! > toastVisibilityTimeoutMS
  );
}

export function shouldShowPrompt(state: PWAStateSlice, now: number) {
  const {
    canShowPrompt,
    configReady,
    newVersionAvailable,
    promptHidden,
    promptDismissed,
    lastSeenReady
  } = state;

  return (
    canShowPrompt &&
    configReady &&
    newVersionAvailable &&
    !promptHidden &&
    !promptDismissed &&
    lastSeenReady &&
    promptNotSeenRecently(state, now)
  );
}

export function shouldShowWarning(state: PWAStateSlice) {
  const {
    configReady,
    newVersionAvailable,
    canShowWarning,
    showingWarning,
    warningDismissed
  } = state;

  return (
    configReady &&
    newVersionAvailable &&
    canShowWarning &&
    !showingWarning &&
    !warningDismissed
  );
}
