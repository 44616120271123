import * as Sentry from "@sentry/react";

const NETWORK_ERROR_SAMPLE_RATE = 0.01;
const LOG_MESSAGE_SAMPLE_RATE = 0.1;

export function applyCustomSampling(
  event: Sentry.Event,
  hint: Sentry.EventHint
): Sentry.Event | null {
  const error = hint.originalException;
  return error instanceof Error
    ? applySamplingToError(event, error)
    : typeof error === "string"
      ? applySamplingToMessage(event, error)
      : event;
}

function applySamplingToError(
  event: Sentry.Event,
  error: Error
): Sentry.Event | null {
  const isNetworkError =
    error.message.match(/Failed to fetch/i) ||
    error.message.match(/Loading chunk .+ failed/i) ||
    error.message.match(/Network Error/i) ||
    error.message.match(/Failed to update a ServiceWorker for scope/i) ||
    error.message.match(/Failed to start the audio device/i) ||
    error.message.match(/Failed to update a ServiceWorker for scope/i) ||
    error.message.match(/^Rejected$/) ||
    error.message.match(
      /Failed to load https?:\/\/www.youtube.com\/iframe_api/
    ) ||
    error.message.match(/Network request failed/i) ||
    error.message.match(/Websocket connection timeout/i) ||
    error.message.match(/Websocket connection error/i);

  if (isNetworkError) {
    return Math.random() > NETWORK_ERROR_SAMPLE_RATE
      ? null
      : {
          ...event,
          tags: { ...event.tags, sampleRate: `${NETWORK_ERROR_SAMPLE_RATE}` }
        };
  }
  return event;
}

function applySamplingToMessage(
  event: Sentry.Event,
  error: string
): Sentry.Event | null {
  const isHighFrequencyError = error.match(
    /Failed to open connection to websocket/i
  );

  if (isHighFrequencyError) {
    return Math.random() > LOG_MESSAGE_SAMPLE_RATE
      ? null
      : {
          ...event,
          tags: { ...event.tags, sampleRate: `${LOG_MESSAGE_SAMPLE_RATE}` }
        };
  }
  return event;
}
