import { DropdownOption } from "common/types";

import UserPreferences from "../models/UserPreferences";
import { getUserSlice } from "./userInfo";

export const getUserPreferences = (state: any): UserPreferences =>
  getUserSlice(state).preferences;

export const getUserHasPickedDisplayName = (state: any) =>
  getUserPreferences(state).hasPickedDisplayName;

export const getMapGradient = (state: any) =>
  getUserPreferences(state).mapGradient;

export const getTypingPreference = (state: any) =>
  getUserPreferences(state).typingPreference;

export const getModuleToolbarOpenByDefault = (state: any) =>
  getUserPreferences(state).moduleToolbarOpenByDefault;

export const getAgeGroup = (state: any) => getUserPreferences(state).ageGroup;

export const getRegion = (state: any) => getUserPreferences(state).region;

export const getAgeGroupPreferenceOption = (
  state: any
): DropdownOption<string> | null | undefined => {
  const ageGroup = getAgeGroup(state);
  return ageGroup
    ? {
        label: ageGroup,
        value: ageGroup
      }
    : null;
};

export const smartLearningIsEnabled = (state: any) =>
  getUserPreferences(state).smartLearningEnabled;

export const getNotifyWhenDataRequestReady = (state: any) =>
  getUserPreferences(state).notifyWhenDataRequestReady;

export function areSoundEffectsEnabled(state: any) {
  return getUserPreferences(state).soundEffectsEnabled;
}

export function getPreferredTheme(state: any) {
  return getUserPreferences(state).preferredTheme;
}

export function getPreferredThemeMode(state: any) {
  return getUserPreferences(state).preferredThemeMode;
}
