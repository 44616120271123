import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";

import useSelectorOnCondition from "seneca-common/utils/hooks/selectors/useSelectorOnCondition";

import { getNewAppVersionAvailable } from "features/app/state";
import { MODAL_ROUTES } from "routing/routes";

import { getUpdateAppModalState, resetUpdateAppModal } from "./state";

export function useReduxNavigationWorkaroundForUpdateModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { reason } = useSelector(getUpdateAppModalState);

  const newAppVersionIsAvailable = useSelectorOnCondition(
    reason,
    getNewAppVersionAvailable
  );

  useEffect(() => {
    if (reason && newAppVersionIsAvailable) {
      dispatch(resetUpdateAppModal());
      navigate(MODAL_ROUTES.UPDATE_APP.buildPath({}), {
        state: MODAL_ROUTES.UPDATE_APP.buildState({
          background: location,
          reason
        })
      });
    }
  }, [reason, dispatch, navigate, location, newAppVersionIsAvailable]);
}
