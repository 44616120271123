import { Client } from "@seneca/client-library";

import {
  FetchXpChallengesResponseBody,
  JoinXpChallengeRequestBody,
  UserXp,
  XpChallenge,
  XpRewardsI
} from "./types";

export default class XpServiceClient extends Client {
  async fetchXP({
    courseId,
    quizId
  }: { courseId?: string; quizId?: string } = {}) {
    const url = `${this.url}/api/xp/me?date="${new Date().toISOString()}"${
      courseId ? `&courseId="${courseId}"` : ""
    }${quizId ? `&quizId="${quizId}"` : ""}`;

    const response = await this.requestMaker.makeRequest(url, {
      method: "GET"
    });

    return response.json as UserXp;
  }

  async fetchXpChallenges() {
    const url = `${this.url}/api/xp-challenges/me`;

    const response = await this.requestMaker.makeRequest(url, {
      method: "GET"
    });

    return response.json as FetchXpChallengesResponseBody;
  }

  async joinChallenge(data: JoinXpChallengeRequestBody) {
    const url = `${this.url}/api/xp-challenges/join`;

    const response = await this.requestMaker.makeRequest(url, {
      method: "POST",
      body: JSON.stringify(data)
    });

    return response.json as XpChallenge;
  }

  async fetchRewards() {
    const url = `${this.url}/api/xp-rewards/me`;

    const response = await this.requestMaker.makeRequest(url, {
      method: "GET"
    });

    return response.json as XpRewardsI;
  }
}
