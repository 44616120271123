import { List } from "immutable";

import { ReceivedSubscriptionProductType } from "seneca-common/features/subscriptions/state/types";
import { buildReduxMapBundle } from "seneca-common/utils/state/build-redux-bundles";

import { FEATURE_NAME } from "./consts";
import Product from "./models/state/subscription-products/subscription-product/product/Product";
import ProductDescription from "./models/state/subscription-products/subscription-product/productDescription/ProductDescription";
import SubscriptionProduct from "./models/state/subscription-products/subscription-product/SubscriptionProduct";

export const { actionTypes, actions, reducer } = buildReduxMapBundle({
  featureName: FEATURE_NAME,
  getIdFromJsObject: getIdFromReceivedSubscriptionProduct,
  jsToImmutableTransform: transformReceivedSubscriptionProduct
});

export function getIdFromReceivedSubscriptionProduct(
  subscriptionProduct: ReceivedSubscriptionProductType
) {
  return subscriptionProduct.id!;
}

export function transformReceivedSubscriptionProduct(
  subscriptionProduct: ReceivedSubscriptionProductType
) {
  return new SubscriptionProduct({
    ...subscriptionProduct,
    product: new Product(subscriptionProduct.product),
    productDescription: new ProductDescription(
      subscriptionProduct.productDescription
    ),
    addonProductIds: List(subscriptionProduct.addonProductIds)
  });
}
