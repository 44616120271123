import { List, Record } from "immutable";

import UpdateOnPaymentSuccess from "seneca-common/features/subscriptions/state/models/subscriptions/subscription/UpdateOnPaymentSuccess";

// TODO import from the client
export const subscriptionStatuses = Object.freeze({
  active: "active",
  cancelled: "cancelled",
  paymentFailed: "paymentFailed",
  paymentRequiresAction: "paymentRequiresAction"
});

export const subscriptionPaymentStatuses = Object.freeze({
  succeeded: "succeeded",
  failed: "failed",
  requiresAction: "requiresAction"
});

export type SubscriptionStatuses =
  (typeof subscriptionStatuses)[keyof typeof subscriptionStatuses];
export type SubscriptionPaymentStatuses =
  (typeof subscriptionPaymentStatuses)[keyof typeof subscriptionPaymentStatuses];

export type Card = {
  brand: string;
  last4: string;
};

export type SubscriptionType = {
  subscriptionId: string | undefined;
  subscriptionProductId: string | undefined;
  addonProductIds: List<string> | undefined;
  status: SubscriptionStatuses | undefined;
  managedBy: string | undefined;
  paymentStatus: SubscriptionPaymentStatuses | undefined;
  expiryDate: string | undefined;
  initialPurchaseDate: string | undefined;
  renewalPurchaseDate?: string | undefined;
  isActive: boolean | undefined;
  stripeSubscriptionId: string | undefined;
  card: Card | undefined;
  canBeReactivated: boolean | undefined;
  updateOnPaymentSuccess: UpdateOnPaymentSuccess | undefined;
  customerId: string | undefined;
  churnkeyUserHash: string | undefined;
};

export type OptionalSubscriptionType = Partial<SubscriptionType>;

export const defaultSubscriptionValues: SubscriptionType = {
  subscriptionId: undefined,
  subscriptionProductId: undefined,
  addonProductIds: List(),
  status: undefined,
  managedBy: undefined,
  paymentStatus: undefined,
  expiryDate: undefined,
  initialPurchaseDate: undefined,
  renewalPurchaseDate: undefined,
  isActive: undefined,
  stripeSubscriptionId: undefined,
  card: undefined,
  canBeReactivated: undefined,
  updateOnPaymentSuccess: undefined,
  customerId: undefined,
  churnkeyUserHash: undefined
};

export default class Subscription extends Record(defaultSubscriptionValues) {}
